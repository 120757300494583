<template>
	<div class="">
		<center-title title="申请对账审批"></center-title>
		<el-table :data="approvalLists" style="width: 100%">
			<el-table-column align="center" prop="applyNickname" label="申请人" width="220">
			</el-table-column>
			<el-table-column align="center" prop="createTime" label="申请时间" width="220">
			</el-table-column>
			<el-table-column align="center" prop="auditStatusText" label="审批状态" width="220">
			</el-table-column>
			<el-table-column align="center" label="操作" width="260">
				<template slot-scope="scope">
				  <div class="primary cursor mb5" @click="dialogVisible = true, auditBox.applySettlementId = scope.row.id">审核</div>
				  <router-link :to="`/applysettlementorderlists?id=${scope.row.id}`">申请对账订单</router-link>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="申请对账审批" :visible.sync="dialogVisible" width="30%">
		  <el-radio-group v-model="auditBox.auditStatus" class="mb20">
		    <el-radio :label="1" :value="1">通过</el-radio>
		    <el-radio :label="0" :value="0">驳回</el-radio>
		  </el-radio-group>
		  <el-input v-if="auditBox.auditStatus == 0" type="textarea" :rows="4" placeholder="请输入驳回内容（驳回时必填）"
		    v-model="auditBox.rejectReason" resize="none">
		  </el-input>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="setRmOperate()">提交</el-button>
		  </span>
		</el-dialog>
		<div class="invoice-footer row-end">
			<make-pagination :orderObject="approvalObject" @resetList="resetList" v-if="approvalLists.length"></make-pagination>
		</div>
	</div>
</template>
<script>
	import CenterTitle from "../components/CenterTitle";
	import MakePagination from "@/components/MakePagination";
	export default {
		name: "",
		props: {},
		components: {
			"center-title": CenterTitle,
			"make-pagination": MakePagination,
		},
		data() {
			return {
				page_no: 1,
				page_size: 10,
				approvalLists: [],
				approvalObject: {},
				dialogVisible: false,
				auditBox: {
				  applySettlementId: '',
				  auditStatus: 1,
				  rejectReason: ''
				}
			};
		},
		computed: {},
		watch: {},
		methods: {
			resetList(e) {
				this.page_no = e;
				this.getApprovalApplySettlementLists();
			},
			/**
			 * @description 获取审批列表
			 * @return void
			 */
			getApprovalApplySettlementLists() {
				let {
					page_no,
					page_size
				} = this;
				this.$api
					.getApprovalApplySettlementListsApi({
						page_no,
						page_size
					})
					.then((res) => {
						this.approvalLists = res.data.lists;
						this.approvalObject = res.data;
					});
			},
			setRmOperate() {
			  this.$api
			    .approvalApplySettlementApi(this.auditBox)
			    .then((res) => {
			      if (res.code == 1) {
					  this.$message({
					  	message: res.msg,
					  	type: 'success'
					  });
			        this.resetList(1);
			        this.dialogVisible = false
			      }
			    });
			},
		},
		created() {
			this.getApprovalApplySettlementLists();
		},
		mounted() {},
		beforeDestroy() {},
	};
</script>
<style lang='scss' scoped>
	.operation {
		padding: 10px 0;

		.el-dropdown {
			font-size: 16px;
		}

		.item {
			/deep/ .el-select {
				.el-input__inner {
					background-color: transparent;
					border: none;
					font-size: 16px;
					text-align: center;
				}

				input::-webkit-input-placeholder {
					color: $-color-normal;
				}

				input::-moz-placeholder {
					color: $-color-normal;
				}

				input:-moz-placeholder {
					color: $-color-normal;
				}

				input:-ms-input-placeholder {
					color: $-color-normal;
				}

				.el-input__icon {
					color: $-color-normal;
				}

				.el-input__suffix {
					right: 15px;
				}
			}
		}
	}

	.order-list {
		.order-item {
			margin-bottom: 15px;

			/deep/ .el-checkbox__label {
				display: none;
			}

			.item-title {
				height: 48px;
				line-height: 48px;
			}

			.order-box {
				border: 1px solid $-color-center;
				border-top: none;
				align-items: flex-start;
			}

			.pro-list {
				.pro-item {
					padding: 20px 0;
					border-bottom: 1px solid $-color-center;
					border-right: 1px solid $-color-center;

					&:last-child {
						border-bottom: none;
					}

					.item-info {
						.item-img {
							width: 64px;
							height: 64px;
							flex-shrink: 0;
						}
					}
				}
			}

			.pro-right {
				padding-top: 44px;

				.operation-btn {
					width: 72px;
					height: 28px;
					border: 1px solid $-color-primary;
					border-radius: 4px;
					display: inline-block;
					color: $-color-primary;
					line-height: 26px;
				}
			}
		}
	}

	.invoice-footer {
		button {
			width: 80px;
			height: 32px;
			border: 1px solid $-color-warn;
			border-radius: 4px;
		}
	}



	/deep/ .el-table {
		border: $-solid-border;
		border-bottom: none;

		th {
			background-color: $-color-center;
			font-size: 16px;
			font-weight: normal;
			color: $-color-lighter;
		}

		td {
			&:nth-child(2) {
				.cell {
					padding: 0 10px;
				}
			}

			.cell {
				padding: 10px 0px;
				line-height: 1.3;
				word-break: break-all;
			}
		}

		th,
		td {
			border-right: $-solid-border;

			&:last-child {
				border-right: none;
			}
		}
	}
</style>
